<template>
  <v-app>
    <dashboard-core-app-bar class="d-print-none" v-model="expandOnHover" />

    <dashboard-core-drawer class="d-print-none" />

    <dashboard-core-view />
  </v-app>
</template>

<script>
export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('./components/core/AppBar'),
    DashboardCoreDrawer: () => import('./components/core/Drawer'),
    DashboardCoreView: () => import('./components/core/View')
  },

  data: () => ({
    expandOnHover: false
  })
}
</script>
